<template>
  <div class="d-flex">
    <div class="d-flex flex-column justify-center align-center flex-shrink mr-4">
      <div class="display-3 text-center" v-text="average" />
      <v-rating v-model="averageStars" dense readonly background-color="grey" />
      <div class="caption d-flex justify-center align-center">
        <v-icon small>tl-person</v-icon>{{ $t('climbs.log.stats.votesTotal', { votes: totalVotes }) }}
      </div>
    </div>
    <div class="d-flex flex-column justify-space-between flex-grow-1">
      <div v-for="n in 5" :key="n" class="d-flex flex-no-wrap">
        <span class="caption" v-text="6 - n" />
        <v-icon small color="grey" style="vertical-align: center">tl-star</v-icon>
        <div class="tl-ratings-graph__bar-bg grey darken-2 ma-1">
          <div class="tl-ratings-graph__bar primary" :style="{ width: percentages[n - 1] }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@/services/utils'

export default {
  props: {
    ratings: { type: Array, default: () => [] },
  },
  computed: {
    totalVotes() {
      return this.ratings.reduce((sum, rating) => sum + rating.votes, 0)
    },
    average() {
      if (!this.totalVotes) return '0.0'
      let sum = this.ratings.reduce((sum, rating) => sum + rating.stars * rating.votes, 0)
      return round(sum / this.totalVotes, 1).toFixed(1)
    },
    averageStars() {
      return round(this.average)
    },
    percentages() {
      let maxVotes = Math.max.apply(
        null,
        this.ratings.map(r => r.votes)
      )
      if (maxVotes <= 0) return ['0%', '0%', '0%', '0%', '0%']
      return this.ratings.map(r => round((100 * r.votes) / maxVotes) + '%')
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-ratings-graph
  &__bar-bg,
  &__bar
    border-radius: 4px
    width: 100%
    transition: width 300ms
  &__bar-bg
    position: relative
  &__bar
    position: absolute
    height: 100%
</style>
