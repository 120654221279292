<template>
  <v-dialog v-model="dialog" :fullscreen="fullscreen" :max-width="400" scrollable @keydown.esc="close">
    <v-card v-if="selection.length" dark>
      <v-list v-if="!isMultiLog" class="py-0" two-line :light="!checks">
        <tl-climbs-list-climb
          :climb="climbs[0]"
          :loggable="loggable"
          :teach-swipe="loggable"
          show-checks
          class="elevation-4"
        />
      </v-list>
      <v-toolbar v-else class="grey darken-4" max-height="56">
        <v-btn v-if="fullscreen" icon @click="close">
          <v-icon>tl-arrow-back</v-icon>
        </v-btn>
        <v-toolbar-title v-text="selectionText" />
      </v-toolbar>

      <v-alert v-if="user !== userViewed" type="info" class="mb-0">
        {{ $t('dashboard.otherUserMsg', { userName: userViewed.fullName }) }}
      </v-alert>

      <v-card-text class="grey darken-3 py-4" style="overflow-x: hidden">
        <v-row v-if="zones == 1 || zonesExpanded" dense>
          <v-col class="text-center" cols="12" v-t="'climbs.log.top'" />
        </v-row>
        <v-row justify="center" class="flex-nowrap" dense>
          <v-col cols="auto" style="visibility: hidden"><v-btn icon/></v-col>
          <v-col v-for="btn in logBtns" :key="btn.checks" cols="auto">
            <v-btn
              class="grey darken-4"
              :class="{
                'primary--text': isChecked(Infinity, btn.checks),
                'disabled-custom': checking || !loggable,
              }"
              :loading="checking && isChecking(Infinity, btn.checks)"
              @click="toggleCheck(Infinity, btn.checks)"
            >
              <v-icon left>tl-{{ btn.icon }}</v-icon>
              {{ $t(`climbs.log.${btn.text}`) }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-menu left offset-y min-width="150">
              <template v-slot:activator="{ on }">
                <v-btn class="grey darken-4 white--text" icon v-on="on"><v-icon>tl-more-vert</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(btn, index) in logBtnsMore" :key="index" @click="btn.handler">
                  <v-list-item-title>{{ $t(btn.text) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-if="zones == 1 || zonesExpanded">
            <v-row v-for="z in zonesArr" :key="z" justify="center" dense>
              <v-col class="text-center" cols="12">
                {{ $t('climbs.log.zone') }}
                <span v-if="zones > 1"> {{ z }}</span>
              </v-col>
              <v-col v-for="btn in logBtns" :key="btn.checks" cols="auto">
                <v-btn
                  class="grey darken-4"
                  :class="{
                    'primary--text': isChecked(z, btn.checks) && !isCheckedIndirect(z, btn.checks),
                    'disabled-custom': checking || isCheckedIndirect(z, btn.checks) || !loggable,
                  }"
                  :loading="checking && isChecking(z, btn.checks)"
                  @click="toggleCheck(z, btn.checks)"
                >
                  <v-icon left>tl-{{ btn.icon }}</v-icon>
                  {{ $t(`climbs.log.${btn.text}`) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>

        <v-row v-if="zones" justify="center">
          <v-col v-if="zones > 1" cols="auto">
            <v-btn class="grey darken-2" @click="zonesExpanded = !zonesExpanded">
              {{ $t('climbs.log.zones') }}
              <v-icon right class="tl-icon-rotatable" :class="{ 'tl-icon-rotatable__rotated': zonesExpanded }">
                tl-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-if="checks">
            <div class="text-center py-2" v-t="'climbs.log.repeats'" />
            <div class="v-btn-toggle v-btn-toggle--rounded d-flex justify-center">
              <v-btn
                class="grey darken-4"
                height="40"
                :loading="checkingRptRmv"
                :disabled="checking || !repeats || !loggable"
                @click="removeRepeat"
              >
                <v-icon>tl-remove</v-icon>
              </v-btn>
              <v-btn class="grey darken-4" height="40" disabled>
                {{ repeats }}
              </v-btn>
              <v-btn
                class="grey darken-4"
                height="40"
                :loading="checkingRptAdd"
                :disabled="checking || !loggable"
                @click="addRepeat"
              >
                <v-icon>tl-add</v-icon>
              </v-btn>
            </div>
          </div>
        </v-expand-transition>

        <tl-climb-opinion :climb="!isMultiLog ? climbs[0] : null" />
        <tl-climb-stats v-if="!isMultiLog" :climb="climbs[0]" />
      </v-card-text>

      <v-card-actions class="grey darken-4">
        <v-spacer />
        <v-btn text color="primary" @click="close">{{ $t('generic.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VBtnToggle } from 'vuetify/lib'
import { mapState, mapGetters, mapActions } from 'vuex'
import Climb from '@/models/Climb'
import tlClimbsListClimb from '../../list/tl-climbs-list-climb'
import tlClimbStats from './tl-climb-stats'
import tlClimbOpinion from './tl-climb-opinion'

export default {
  components: {
    tlClimbsListClimb,
    tlClimbStats,
    tlClimbOpinion,
    VBtnToggle, // eslint-disable-line
  },
  data: () => ({
    closed: false,
    zonesExpanded: false,
    checking: false,
    checkingChecks: 0,
    checkingZone: 0,
    checkingRptAdd: false,
    checkingRptRmv: false,
  }),
  computed: {
    ...mapState(['user', 'gym', 'climbType']),
    ...mapState('selection', ['selection']),
    ...mapGetters(['userViewed']),
    dialog: {
      get() {
        return true
      },
      set(open) {
        if (!open && !this.closed) this.close()
      },
    },
    fullscreen() {
      return this.$vuetify.breakpoint.xsOnly
    },
    loggable() {
      return this.user === this.userViewed
    },
    isRoute() {
      return this.climbType == 'route'
    },
    climbs() {
      return this.selection
    },
    isMultiLog() {
      return this.climbs.length > 1
    },
    zones() {
      return !this.isMultiLog && this.climbs[0].zones
    },
    selectionText() {
      let climbsTrans = this.$t(`generic.${this.climbType}s`)
      return `${this.climbs.length} ${climbsTrans}`
    },
    logBtns() {
      return [
        { checks: 3, icon: 'check-3', text: 'os', disabled: !this.isRoute },
        { checks: 2, icon: 'check-2', text: this.isRoute ? 'fl' : 'flash' },
        { checks: 1, icon: 'check', text: this.isRoute ? 'rp' : 'done' },
      ].filter(btn => !btn.disabled)
    },
    logBtnsMore() {
      return [
        { text: 'climbs.log.project', disabled: true },
        { text: 'Tried', disabled: true },
        { text: 'Lead / TopRope', disabled: !this.isRoute || true },
        { text: 'sidenav.help', handler: this.showLogExplanation },
      ].filter(btn => !btn.disabled)
    },
    checks() {
      return Climb.getCommonChecks(this.climbs)
    },
    repeats() {
      return Climb.getCommonTops(this.climbs) - 1
    },
    zonesArr() {
      return Array.from(Array(this.zones), (x, i) => i + 1).reverse()
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelection()
    next()
  },
  methods: {
    ...mapActions('selection', { clearSelection: 'clear' }),
    close() {
      this.closed = true
      this.$router.pushSmart({
        name: this.$route.name.replace(/\.climb$/, ''),
        params: { climbId: null },
      })
    },
    isChecked(zone, checks) {
      if (zone == Infinity) {
        return Climb.getCommonChecks(this.climbs, { exact: true }) == checks
      } else {
        if (this.isMultiLog) return false // No zones for multi-checks
        return !!this.climbs[0].ascendsFiltered(zone, checks).length
      }
    },
    isCheckedIndirect(zone, checks) {
      if (this.isMultiLog) return false // No zones for multi-checks
      return this.climbs[0].ascendsFiltered().some(a => a.zoneOrInf > zone && a.checks >= checks)
    },
    isChecking(zone, checks) {
      return zone == this.checkingZone && checks == this.checkingChecks
    },
    toggleCheck(zone, checks) {
      this.checking = true
      this.checkingChecks = checks
      this.checkingZone = zone
      this.user.toggleCheck(this.climbs, zone, checks).finally(() => (this.checking = false))
    },
    addRepeat() {
      this.checking = true
      this.checkingRptAdd = true
      this.user.addRepeat(this.climbs).finally(() => {
        this.checking = false
        this.checkingRptAdd = false
      })
    },
    removeRepeat() {
      this.checking = true
      this.checkingRptRmv = true
      this.user.removeRepeat(this.climbs).finally(() => {
        this.checking = false
        this.checkingRptRmv = false
      })
    },
    showLogExplanation() {
      this.$store.dispatch('dialog/open', {
        component: () => import(/* webpackChunkName: 'tl-climbs' */ './tl-climbs-logger-expl.vue'),
        props: {
          grade: this.climbs[0].grade,
          maxWidth: 700,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-icon-rotatable
  transition: transform 0.3s
  &__rotated
    transform: rotate(180deg)
.v-btn.disabled-custom
  pointer-events: none
  background-color: rgba(255, 255, 255, 0.12) !important
</style>
