<template>
  <div class="d-flex flex-column fill-height">
    <tl-climbs-logging />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import climbsStore from '../climbs.store'
import multiguard from 'vue-router-multiguard'
import uidGuard from '@/components/gym/uid.guard'
import climbsTypeGuard from '../climbs-type.guard'
import tlClimbsLogging from './tl-climbs-logging'

export default {
  components: {
    tlClimbsLogging,
  },
  head() {
    if (!this.$i18n) return {} // Some weired bug when re-visiting a page.
    return {
      title: this.$t('climbs.title', { gymName: this.gym.name, routesBoulders: this.climbsTypeTrans }),
      meta: [{ vmid: 'description', name: 'description', content: this.description }],
    }
  },
  computed: {
    ...mapState(['gym', 'climbType']),
    ...mapState('climbs', ['view']),
    description() {
      let view = this.view == 'list' ? 'List' : 'Map'
      return this.$t(`climbs.description${view}`, {
        gymName: this.gym.name,
        routesBoulders: this.climbsTypeTrans.toLowerCase(),
      })
    },
    climbsTypeTrans() {
      return this.$t(`generic.${this.climbType}s`)
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('climbs', climbsStore)
  },
  beforeRouteEnter: multiguard([uidGuard, climbsTypeGuard]),
  beforeRouteUpdate: multiguard([uidGuard, climbsTypeGuard]),
}
</script>
