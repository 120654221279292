var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.fullscreen,"max-width":400,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selection.length)?_c('v-card',{attrs:{"dark":""}},[(!_vm.isMultiLog)?_c('v-list',{staticClass:"py-0",attrs:{"two-line":"","light":!_vm.checks}},[_c('tl-climbs-list-climb',{staticClass:"elevation-4",attrs:{"climb":_vm.climbs[0],"loggable":_vm.loggable,"teach-swipe":_vm.loggable,"show-checks":""}})],1):_c('v-toolbar',{staticClass:"grey darken-4",attrs:{"max-height":"56"}},[(_vm.fullscreen)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("tl-arrow-back")])],1):_vm._e(),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.selectionText)}})],1),(_vm.user !== _vm.userViewed)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.otherUserMsg', { userName: _vm.userViewed.fullName }))+" ")]):_vm._e(),_c('v-card-text',{staticClass:"grey darken-3 py-4",staticStyle:{"overflow-x":"hidden"}},[(_vm.zones == 1 || _vm.zonesExpanded)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{directives:[{name:"t",rawName:"v-t",value:('climbs.log.top'),expression:"'climbs.log.top'"}],staticClass:"text-center",attrs:{"cols":"12"}})],1):_vm._e(),_c('v-row',{staticClass:"flex-nowrap",attrs:{"justify":"center","dense":""}},[_c('v-col',{staticStyle:{"visibility":"hidden"},attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""}})],1),_vm._l((_vm.logBtns),function(btn){return _c('v-col',{key:btn.checks,attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"grey darken-4",class:{
              'primary--text': _vm.isChecked(Infinity, btn.checks),
              'disabled-custom': _vm.checking || !_vm.loggable,
            },attrs:{"loading":_vm.checking && _vm.isChecking(Infinity, btn.checks)},on:{"click":function($event){return _vm.toggleCheck(Infinity, btn.checks)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("tl-"+_vm._s(btn.icon))]),_vm._v(" "+_vm._s(_vm.$t(("climbs.log." + (btn.text))))+" ")],1)],1)}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"left":"","offset-y":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey darken-4 white--text",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("tl-more-vert")])],1)]}}],null,false,3886842735)},[_c('v-list',_vm._l((_vm.logBtnsMore),function(btn,index){return _c('v-list-item',{key:index,on:{"click":btn.handler}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(btn.text)))])],1)}),1)],1)],1)],2),_c('v-expand-transition',[(_vm.zones == 1 || _vm.zonesExpanded)?_c('div',_vm._l((_vm.zonesArr),function(z){return _c('v-row',{key:z,attrs:{"justify":"center","dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('climbs.log.zone'))+" "),(_vm.zones > 1)?_c('span',[_vm._v(" "+_vm._s(z))]):_vm._e()]),_vm._l((_vm.logBtns),function(btn){return _c('v-col',{key:btn.checks,attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"grey darken-4",class:{
                  'primary--text': _vm.isChecked(z, btn.checks) && !_vm.isCheckedIndirect(z, btn.checks),
                  'disabled-custom': _vm.checking || _vm.isCheckedIndirect(z, btn.checks) || !_vm.loggable,
                },attrs:{"loading":_vm.checking && _vm.isChecking(z, btn.checks)},on:{"click":function($event){return _vm.toggleCheck(z, btn.checks)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("tl-"+_vm._s(btn.icon))]),_vm._v(" "+_vm._s(_vm.$t(("climbs.log." + (btn.text))))+" ")],1)],1)})],2)}),1):_vm._e()]),(_vm.zones)?_c('v-row',{attrs:{"justify":"center"}},[(_vm.zones > 1)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"grey darken-2",on:{"click":function($event){_vm.zonesExpanded = !_vm.zonesExpanded}}},[_vm._v(" "+_vm._s(_vm.$t('climbs.log.zones'))+" "),_c('v-icon',{staticClass:"tl-icon-rotatable",class:{ 'tl-icon-rotatable__rotated': _vm.zonesExpanded },attrs:{"right":""}},[_vm._v(" tl-chevron-down ")])],1)],1):_vm._e()],1):_vm._e(),_c('v-expand-transition',[(_vm.checks)?_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('climbs.log.repeats'),expression:"'climbs.log.repeats'"}],staticClass:"text-center py-2"}),_c('div',{staticClass:"v-btn-toggle v-btn-toggle--rounded d-flex justify-center"},[_c('v-btn',{staticClass:"grey darken-4",attrs:{"height":"40","loading":_vm.checkingRptRmv,"disabled":_vm.checking || !_vm.repeats || !_vm.loggable},on:{"click":_vm.removeRepeat}},[_c('v-icon',[_vm._v("tl-remove")])],1),_c('v-btn',{staticClass:"grey darken-4",attrs:{"height":"40","disabled":""}},[_vm._v(" "+_vm._s(_vm.repeats)+" ")]),_c('v-btn',{staticClass:"grey darken-4",attrs:{"height":"40","loading":_vm.checkingRptAdd,"disabled":_vm.checking || !_vm.loggable},on:{"click":_vm.addRepeat}},[_c('v-icon',[_vm._v("tl-add")])],1)],1)]):_vm._e()]),_c('tl-climb-opinion',{attrs:{"climb":!_vm.isMultiLog ? _vm.climbs[0] : null}}),(!_vm.isMultiLog)?_c('tl-climb-stats',{attrs:{"climb":_vm.climbs[0]}}):_vm._e()],1),_c('v-card-actions',{staticClass:"grey darken-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('generic.close')))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }