<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-t="'climbs.log.help.header'" />
    </v-card-title>
    <v-divider />
    <v-card-text class="text--primary">
      <div class="py-4">
        <h3 class="title" v-t="'climbs.log.help.buttons.header'" />
        <p class="subtitle-1" v-t="'climbs.log.help.buttons.subhead'" />
        <tl-help-logging-boulder v-if="climbType == 'boulder'" />
        <tl-help-logging-route v-if="climbType == 'route'" />
      </div>

      <v-divider />
      <tl-help-logging-stability class="py-4" />

      <v-divider />
      <div class="py-4">
        <h3 class="title pb-4" v-t="'help.faq.subhead'" />
        <v-row align="center" no-gutters>
          <v-col cols="2">
            <v-icon large>tl-question-answer</v-icon>
          </v-col>
          <v-col cols="10">
            <span class="mt-4 subtitle-2">"{{ $t('climbs.log.help.questions.QgradeChange') }}"</span>
          </v-col>
          <v-col cols="10" offset="2">
            <span v-t="'climbs.log.help.questions.AgradeChange'" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary" text @click="close" v-t="'generic.gotIt'" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import tlHelpLoggingRoute from '@/components/extras/help/tl-help-logging-route'
import tlHelpLoggingBoulder from '@/components/extras/help/tl-help-logging-boulder'
import tlHelpLoggingStability from '@/components/extras/help/tl-help-logging-stability'

export default {
  components: {
    tlHelpLoggingRoute,
    tlHelpLoggingBoulder,
    tlHelpLoggingStability,
  },
  computed: {
    ...mapState(['climbType']),
  },
  methods: {
    ...mapActions('dialog', ['close']),
  },
}
</script>
