<template>
  <div>
    <v-divider class="my-4" />
    <h3 class="title pb-4" v-t="'climbs.log.stats.votesTitle'" />
    <tl-grade-votes-graph :votes="grades" :grade="climb.grade" :loading="loading" />

    <v-divider class="my-4" />
    <div class="pb-4">
      <span class="title" v-t="'climbs.log.stats.ratingTitle'" />
      <v-fade-transition>
        <span class="subtitle" v-if="loading"> {{ $t('generic.loading') }}...</span>
      </v-fade-transition>
    </div>
    <tl-ratings-graph :ratings="ratings" />

    <v-divider class="my-4" />
    <h3 class="title" v-t="'climbs.log.stats.toppedHeader'" />
    <span class="caption" v-t="'climbs.log.stats.toppedSubhead'" />

    <v-text-field
      v-if="!loading && toppers.length > 5"
      v-model="toppersSearch"
      single-line
      hide-details
      prepend-icon="tl-search"
      :label="$t('generic.search')"
      clearable
    />
    <p class="text-center py-4" v-if="loading">{{ $t('generic.loading') }}...</p>
    <v-list v-if="!loading" flat two-line>
      <p v-if="!toppers.length" class="text-center py-4">
        {{ $t(`climbs.log.stats.notTopped${climbType == 'route' ? 'Route' : 'Boulder'}`) }}
      </p>
      <v-list-item v-if="toppersSearch && !toppersFiltered.length">
        <v-list-item-title>{{ $t('generic.searchNoResults', { search: toppersSearch }) }}</v-list-item-title>
      </v-list-item>
      <template v-for="(u, index) in toppersPaginated">
        <v-list-item :key="u.id" class="px-0" @click="showUserClimbs(u.uid)">
          <tl-list-item-avatar :avatar="u.avatar" class="mr-4" />
          <v-list-item-content>
            <v-list-item-title v-text="u.full_name" />
            <v-list-item-subtitle v-text="moment(u.date_logged).fromNow()" />
          </v-list-item-content>
          <v-list-item-action>{{ $t(`climbs.log.${getTopType(u.checks)}`) }}</v-list-item-action>
        </v-list-item>
        <v-divider :key="-index" />
      </template>
    </v-list>

    <tl-pagination-local :list="toppersFiltered" @list-paginated="toppersPaginated = $event" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Climb from '@/models/Climb'
import tlGradeVotesGraph from '../../shared/tl-grade-votes-graph'
import tlRatingsGraph from '../../shared/tl-ratings-graph'
import tlListItemAvatar from '@/components/shared/tl-list-item-avatar'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlGradeVotesGraph,
    tlRatingsGraph,
    tlListItemAvatar,
    tlPaginationLocal,
  },
  props: {
    climb: { type: Climb, default: () => {} },
  },
  data: () => ({
    loading: true,
    grades: [],
    ratings: [],
    toppers: [],
    toppersPaginated: [],
    toppersSearch: null,
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    toppersFiltered() {
      if (!this.toppersSearch) return this.toppers
      return this.toppers.filter(topper => {
        return topper.full_name.toLowerCase().indexOf(this.toppersSearch.toLowerCase()) > -1
      })
    },
  },
  created() {
    this.fetchStats()
  },
  methods: {
    fetchStats() {
      const params = {
        gym_id: this.gym.id,
        climb_id: this.climb.id,
      }
      Climb.$apiCall('stats', { params })
        .then(stats => {
          this.grades = stats.community_grades
          this.ratings = stats.community_opinions
          this.toppers = stats.toppers
        })
        .finally(() => (this.loading = false))
    },
    showUserClimbs(uid) {
      this.$store.dispatch('selection/clear')
      this.$router.pushSmart({
        name: this.$route.name.replace(/\.climb$/, ''),
        query: { uid },
      })
    },
    getTopType(checks) {
      if (checks == 3) return 'os'
      if (checks == 2) return 'fl'
      return 'rp'
    },
  },
}
</script>
