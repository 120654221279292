<template>
  <div class="d-flex flex-column fill-height">
    <tl-climbs-logging :loading="loadingComp" :toolbar-title="comp && comp.name" :toolbar-btn-left="toolbarBtnLeft" />
    <router-view />
  </div>
</template>

<script>
import store from '@/store'
import Comp from '@/models/Comp'
import climbsStore from '@/components/gym/climbs/climbs.store'
import multiguard from 'vue-router-multiguard'
import uidGuard from '@/components/gym/uid.guard'
import tlClimbsLogging from '@/components/gym/climbs/logging/tl-climbs-logging.vue'

function viewFilterEnterGuard(to, from, next) {
  store.registerModuleOnce('climbs', climbsStore)
  store.commit('climbs/viewFilters/setCompId', to.params.compId)
  next()
}

function viewFilterLeaveGuard(to, from, next) {
  store.commit('climbs/viewFilters/setCompId', null)
  next()
}

export default {
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  components: {
    tlClimbsLogging,
  },
  head() {
    return {
      title: this.comp && this.comp.name,
      meta: [{ vmid: 'description', name: 'description', content: this.comp && this.comp.name }],
    }
  },
  computed: {
    toolbarBtnLeft() {
      return { icon: 'tl-arrow-back', action: this.$router.toBackRoute }
    },
  },
  beforeRouteEnter: multiguard([uidGuard, viewFilterEnterGuard]),
  beforeRouteUpdate: multiguard([uidGuard, viewFilterEnterGuard]),
  beforeRouteLeave: viewFilterLeaveGuard,
  watch: {
    loadingComp: {
      immediate: true,
      handler() {
        if (this.loadingComp) return
        let startDate = this.moment(this.comp.date_loggable_start).fromNow()
        let compName = this.comp.name
        this.$store.dispatch('toast/info', this.$t('competitions.logSubhead', { compName, startDate }))
      },
    },
  },
}
</script>
