<template>
  <div>
    <v-row justify="center">
      <v-col cols="auto">
        <v-rating
          :value="opinion.rating"
          :readonly="disabled"
          clearable
          large
          dense
          color="primary"
          :background-color="disabled ? 'grey darken-2' : 'grey darken-4'"
          @input="setRating"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="grade-opinion">
        <v-expand-transition>
          <div v-if="(climb && climb.checks > 0) || user.isSetter || observing">
            <v-scale-transition>
              <span class="grade-opinion__label" v-show="!(opinion.grade > 2)" v-t="'climbs.log.myOpinion'" />
            </v-scale-transition>
            <tl-grade-slider
              :value="opinion.grade"
              :type="climbType"
              :disabled="disabled"
              @input="opinion.$update({ grade: $event })"
            />
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>

    <v-row justify="center" class="flex-nowrap" no-gutters>
      <v-col cols="4" v-for="btn in actionBtns" :key="btn.icon" class="d-flex flex-column align-center px-2">
        <v-btn
          class="mb-2"
          outlined
          icon
          large
          :color="opinion[btn.prop] ? 'primary' : ''"
          :disabled="disabled"
          @click="btn.handler"
        >
          <v-icon>tl-{{ btn.icon }}</v-icon>
        </v-btn>
        <span class="caption text-center text-uppercase">
          {{ $t(`climbs.log.${btn.text}`, { thisRouteBoulder: thisRouteBoulderTrans }) }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { nowRoundedToSec } from '@/services/utils'
import Climb from '@/models/Climb'
import Opinion from '@/models/Opinion'
import tlGradeSlider from '@/components/shared/tl-grade-sliders/tl-grade-slider'

export default {
  components: {
    tlGradeSlider,
  },
  props: {
    climb: { type: Climb, default: () => {} },
  },
  data: () => ({
    opinion: {},
  }),
  computed: {
    ...mapState(['user', 'gym', 'climbType']),
    ...mapState('kiosk', ['isKiosk']),
    ...mapGetters(['userViewed']),
    observing() {
      return this.user !== this.userViewed
    },
    disabled() {
      return !this.climb || this.observing // this.climb is null in multiSelect mode
    },
    thisRouteBoulderTrans() {
      return this.$t(this.climbType == 'boulder' ? 'generic.thisBoulder' : 'generic.thisRoute')
    },
    actionBtns() {
      return [
        { prop: 'project', icon: 'target', text: 'project', handler: this.toggleProject, enabled: true },
        {
          prop: 'voted_renew',
          icon: 'autorenew',
          text: this.opinion.voted_renew ? 'renew.unvote' : 'renew.vote',
          handler: this.toggleVoteRenew,
          enabled: this.gym.vote_renewal,
        },
        {
          icon: 'priority-high',
          text: 'report.reportClimb',
          handler: this.openReportDialog,
          enabled: this.gym.report_btn,
        },
      ].filter(btn => btn.enabled)
    },
  },
  created() {
    if (!this.climb) return
    this.opinion = this.climb.opinions.find(o => o.user_id == this.userViewed.id)
    if (!this.opinion) {
      this.opinion = new Opinion({ user_id: this.userViewed.id, climb_id: this.climb.id })
      Opinion.inject(this.opinion)
    }
  },
  beforeDestroy() {
    if (this.disabled || this.user.guest) return
    this.opinion.$apiSave()
  },
  methods: {
    ...mapActions('dialog', ['close', 'prompt', 'confirm']),
    ...mapActions('toast', { toastSuccess: 'success' }),
    setRating(rating) {
      if (rating == 0) {
        this.opinion.$update({ rating: null, date_rated: null })
      } else {
        this.opinion.$update({ rating, date_rated: nowRoundedToSec() })
      }
    },
    toggleProject() {
      this.opinion.$update({ project: !this.opinion.project })
    },
    toggleVoteRenew() {
      const newVal = !this.opinion.voted_renew
      this.opinion.$update({
        voted_renew: newVal,
        date_voted_renew: newVal ? nowRoundedToSec() : null,
      })
      const key = newVal ? 'voted' : 'unvoted'
      const votedTitle = this.$t(`climbs.log.renew.${key}`)
      const votedMsg = this.$t(`climbs.log.renew.${key}Msg`, { thisRouteBoulder: this.thisRouteBoulderTrans })
      this.toastSuccess(`${votedTitle} - ${votedMsg}`)
    },
    openReportDialog() {
      if (this.isKiosk) return this.$store.dispatch('kiosk/showGetAppDialog')
      if (this.user.guest)
        return this.confirm({
          title: this.$t('climbs.guestMsg'),
          text: this.$t('auth.actionRequiresLogin'),
          ok: this.$t('auth.login'),
          onSave: () => this.$router.pushSmart({ name: 'auth.login' }),
        })
      this.prompt({
        title: this.$t('climbs.log.report.reportClimb', { thisRouteBoulder: this.thisRouteBoulderTrans }),
        text: this.$t('climbs.log.report.explanation'),
        label: this.$t('climbs.log.report.messageLabel'),
        ok: this.$t('climbs.log.report.report'),
        onSave: this.setReportMessage,
      })
    },
    setReportMessage(message) {
      this.opinion.$update({
        report_message: message,
        date_reported: nowRoundedToSec(),
        report_resolved: null,
        report_removed: null,
        date_report_removed: null,
      })
      this.opinion.$apiSave()
      this.toastSuccess(this.$t('climbs.log.report.successMsg'))
    },
  },
}
</script>

<style lang="sass" scoped>
.grade-opinion
  position: relative
  &__label
    @include tl-center
    top: 2em
</style>
