<template>
  <tl-climbs :loading="loadingAny" :loggable="loggable" show-checks />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import climbsRouteMixin from '@/components/gym/climbs/tl-climbs-route.mixin'
import uidMixin from '@/components/gym/uid.mixin'
import tlClimbFilters from '../filters/tl-climb-filters'
import tlClimbs from '../tl-climbs'
import Ascend from '@/models/Ascend'
import Opinion from '@/models/Opinion'

export default {
  mixins: [climbsRouteMixin, toolbarMixin, uidMixin],
  components: {
    tlClimbs,
  },
  props: {
    loading: { type: Boolean, default: false },
    toolbarTitle: { type: String, default: '' },
    toolbarBtnLeft: { type: Object, default: null },
  },
  data: () => ({
    loadingUserData: false,
  }),
  computed: {
    ...mapState(['user', 'gym', 'climbType']),
    ...mapState('climbs', ['view']),
    ...mapGetters(['userViewed']),
    routeNameOverview() {
      return this.$route.name.replace(/\.climb$/, '') // We can be in the comp-logging route
    },
    loadingAny() {
      return this.loading || this.loadingUserData
    },
    tlToolbarBtnLeft() {
      if (this.selection.length) return { icon: 'tl-arrow-back', action: this.clearSelection }
      return this.toolbarBtnLeft
    },
    tlToolbarTitle() {
      if (this.selection.length) return this.$t('profile.notifications.gymsSelected', { number: this.selection.length })
      return this.toolbarTitle || this.$t(`generic.${this.climbType}s`)
    },
    tlToolbarDark() {
      return !!this.selection.length
    },
    tlToolbarButtons() {
      let btnCheck = { icon: 'tl-check', text: this.$t('climbs.log.tickOff'), action: this.openLogger }
      let btnFilter = {
        icon: 'tl-tune',
        iconBadge: !this.$store.getters['climbs/userFilters/isDefault'] ? 'tl-priority-high' : false,
        text: this.$t('climbs.filters.filters'),
        action: this.toggleSideNav,
      }
      let btnSelFiltered = {
        text: this.$t('climbs.filters.selectAllFiltered'),
        action: this.selectAllFiltered,
      }
      let btnSelInView = {
        text: this.$t('climbs.filters.selectAllInView'),
        action: this.selectAllInView,
        hidden: this.view == 'list' || this.view == 'table',
      }
      let btnRefresh = {
        text: this.$t('generic.refresh'),
        action: this.refresh,
      }
      return this.selection.length ? [btnCheck] : [btnFilter, btnSelFiltered, btnSelInView, btnRefresh]
    },
    loggable() {
      return this.userViewed == this.user
    },
    userViewedBanner() {
      if (!this.userViewed || this.userViewed == this.user) return null
      let btns = [
        {
          text: this.$t('dashboard.showMine'),
          action: () => {
            this.$router.replaceSmart({
              name: this.routeNameOverview,
              query: { uid: undefined },
            })
          },
        },
      ].filter(btn => !btn.hide)

      return {
        id: 'userViewed',
        icon: 'tl-target-account',
        text: this.$t('dashboard.otherUserMsg', { userName: this.userViewed.fullName }),
        btns,
      }
    },
  },
  created() {
    this.fetchLoggingData()
  },
  mounted() {
    this.$store.commit('nav-right/setComponent', tlClimbFilters)
  },
  watch: {
    '$route.query.uid'() {
      this.fetchLoggingData()
    },
    selection() {
      if (this.$store.state.climbs.loading) return
      if (this.selection.length == 1 && !this.multiSelectMode) this.openLogger()
    },
    userViewedBanner: {
      immediate: true,
      handler() {
        this.updateUserViewedBanner()
      },
    },
  },
  methods: {
    ...mapActions('selection', { clearSelection: 'clear' }),
    updateUserViewedBanner() {
      this.userViewedBanner
        ? this.$store.commit('toolbar/setIdBanner', this.userViewedBanner)
        : this.$store.commit('toolbar/removeIdBanner', 'userViewed')
    },
    fetchLoggingData() {
      Ascend.ejectAll()
      Opinion.ejectAll()
      if (!this.$route.query.uid) return
      const filters = {
        used: true, // Legacy, can be filtered out on the backend after moving to vue.
        user: {
          uid: this.$route.query.uid,
        },
        climb: {
          gym_id: this.gym.id,
          deleted: false,
          live: true,
        },
      }
      this.loadingUserData = true
      let dfdAscends = Ascend.$apiAll({ params: { json_params: { filters }, serialize_checks: true } })
      let dfdOpinions = Opinion.$apiAll({ params: { json_params: { filters } } })
      return Promise.all([dfdAscends, dfdOpinions]).finally(() => {
        this.loadingUserData = false
      })
    },
    refresh() {
      this.fetchLoggingData()
      this.$store.dispatch('climbs/fetch')
    },
    toggleSideNav() {
      this.$store.commit('nav-right/toggle')
    },
    openLogger() {
      if (!this.selection.length) return
      this.$router.replaceSmart({
        name: this.routeNameOverview + '.climb',
        params: {
          climbId: this.selection.length == 1 ? this.selection[0].id : 'multi',
        },
      })
    },
  },
}
</script>
